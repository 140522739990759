// Capitalizes the first letter of string and returns the new string
function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Determines if text in the given reference is exceeding the width of the container
const isTextOverflowing = (ref: any): boolean => {
  if (ref && ref.current) {
    const inputField = ref.current;
    return inputField.clientWidth < inputField.scrollWidth;
  }
  return false;
};

const clamp = (num: number, lowerBound: number, upperBound: number) =>
  upperBound
    ? Math.min(Math.max(num, lowerBound), upperBound)
    : Math.min(num, lowerBound);

export { capitalizeFirstLetter, isTextOverflowing, clamp };
