import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Empty as AntdEmpty } from 'antd';

import EmptyIcon from '@assets/Icons/Empty.svg';

interface Props {
  children?: React.ReactNode;
  image?: React.ReactNode;
  description?: string;
  imageStyle?: any;
  style?: React.CSSProperties;
}

const StyledEmpty = styled(AntdEmpty)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;

  .ant-empty-description {
    font-size: 1.3em;
    color: ${(props) => props.theme.color.gray900};
  }
`;

const Empty = (props: Props): JSX.Element => {
  const theme = useTheme();

  const imageStyle = {
    fill: theme.color.gray900,
    height: '4rem',
  };

  const EmptyElement: React.ReactNode = <EmptyIcon />;

  return (
    <StyledEmpty
      style={props.style}
      image={EmptyElement}
      description={props.description || 'No Data'}
      imageStyle={props.imageStyle || imageStyle}
    />
  );
};

export default Empty;
