import React from 'react';

import { useReactiveVar } from '@apollo/client';
// import Image from 'next/legacy/image';
import styled from '@emotion/styled';
import { Layout } from 'antd';
import { useRouter } from 'next/router';
import { IoClose, IoSearch } from 'react-icons/io5';

// import KoiVision from '@assets/Icons/AllApps/KoiVision.svg';
// import WaveBackground from '@assets/Icons/AllApps/WaveBackground.svg';
import HomeIcon from '@assets/Icons/Home.svg';
import KoiReaderEmblem from '@assets/KoiReaderEmblem.svg';
import PageHeader from '@components/elements/PageHeader';
import AppLayout from '@components/layouts/appLayout';
import { subscribedDashboardAppsVar } from 'src/apollo/reactiveVars';
// import { THIRD_PARTIES } from 'src/global/constants/thirdParties';

import AllAppsList from './AllAppsList';
import {
  HomeContainer,
  // StyledFooter,
  StyledPage,
  StyledSearchInput,
  StyledTitle,
} from './styledComponents';
import { getAppGroups, getSelectedAppGroup } from './utils';

const { Header } = Layout;

const AntHeader = styled(Header)`
  height: 62px;
  font-size: 20px;
  font-family: Muli, Arial;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const AllAppsPage = () => {
  const router = useRouter();

  const subscribedDashboardApps = useReactiveVar(subscribedDashboardAppsVar);

  const tab = router.query.tab as string;

  const appGroups = React.useMemo(
    () => getAppGroups(subscribedDashboardApps),
    [subscribedDashboardApps]
  );

  const selectedAppGroup = React.useMemo(
    () => getSelectedAppGroup(tab, appGroups),
    [tab, appGroups]
  );

  const changeView = (tab: string) => {
    router.push(`${router.pathname}?tab=${tab}`, undefined, {
      shallow: true,
    });
  };

  // Adding home tab consisting of all apps data initially
  const sidebarTabs = [
    {
      id: 'allApps',
      title: 'Home',
      icon: <HomeIcon />,
      onClick: () => changeView('allApps'),
    },
  ];

  // Appending all app groups data dynamically to sidebar props
  for (const group of appGroups) {
    sidebarTabs.push({
      id: group.id,
      title: group.title,
      icon: group.icon,
      onClick: () => changeView(group.id),
    });
  }

  const SidebarProps = {
    logo: <KoiReaderEmblem />,
    tabs: sidebarTabs,
  };

  const [searchValue, setSearchValue] = React.useState('');

  // Only home tab has multiple app groups
  const isHomeTab = selectedAppGroup.length > 1;

  // Reset search value when app group changes
  React.useEffect(() => {
    setSearchValue('');
  }, [selectedAppGroup]);

  return (
    <AppLayout sidebarProps={SidebarProps}>
      <HomeContainer data-cy-page="all-apps">
        <AntHeader>
          <PageHeader icon={<HomeIcon />} title="Apps" titleLink={'/'} />
        </AntHeader>
        <StyledPage>
          {/* <WaveBackground className="background" /> */}
          <div className="wrapper">
            {!isHomeTab && (
              <StyledTitle>
                {/* {isHomeTab ? 'All Apps' : appGroups[0].title} */}
                {selectedAppGroup[0].title}
              </StyledTitle>
            )}
            <StyledSearchInput
              placeholder="Search"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              suffix={
                searchValue.length > 0 ? (
                  <IoClose onClick={() => setSearchValue('')} />
                ) : (
                  <IoSearch />
                )
              }
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={true}
            />
          </div>

          <AllAppsList appGroups={selectedAppGroup} searchValue={searchValue} />

          {/* <StyledFooter>
            <div className="title">
              <span className="icon">
                <KoiVision />
              </span>
              <span>Connectivity with 10+ Platforms</span>
              <div className="accent-design"></div>
            </div>
            <div className="third-parties">
              {THIRD_PARTIES.map((thirdParty) => (
                <Image
                  src={thirdParty.logoSrc}
                  key={thirdParty.id}
                  alt={thirdParty.id}
                />
              ))}
            </div>
          </StyledFooter> */}
        </StyledPage>
      </HomeContainer>
    </AppLayout>
  );
};
