import React from 'react';

// import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
// import DotLoader from 'react-spinners/DotLoader';
import PulseLoader from 'react-spinners/PulseLoader';

const StyledLoader = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  loading: boolean;
  color?: string;
  margin?: number;
  speedMultiplier?: number;
  style?: any;
  size?: number | string;
}

const Loader = (props: Props): JSX.Element => {
  // const theme = useTheme();

  return (
    <StyledLoader style={props.style}>
      {/* <DotLoader loading={true} color={theme.color.gray900} /> */}
      <PulseLoader
        size={props.size ?? 15}
        loading={props.loading}
        color={props.color ?? `var(--ant-color-primary)`}
        margin={props.margin ?? 3}
        speedMultiplier={props.speedMultiplier ?? 0.5}
      />
    </StyledLoader>
  );
};

export default Loader;
