import React from 'react';

import { useReactiveVar } from '@apollo/client';
import styled from '@emotion/styled';
import { Divider, Flex } from 'antd';
// import Link from 'next/link';
import { useRouter } from 'next/router';
import Avatar from 'react-avatar';

// import AllAppsIcon from '@assets/Icons/Apps.svg';
// import Bell from '@assets/Icons/Bell.svg';
import Tooltip from '@components/elements/Tooltip';
import { userVar } from 'src/apollo/reactiveVars';

import NotificationDrawer from '../NotificationDrawer';
import ProfileDrawer from '../ProfileDrawer';

const StyledDiv = styled(Flex)`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.7);
  background-color: var(--ant-color-bg-container);

  .part-top {
    width: 4.5em;
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.color.gray700};
    /* background-color: ${(props) => props.theme.color.primary800}; */

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5em;
      width: 100%;
      height: 4.5em;
      cursor: default;

      svg {
        /* color: ${(props) => props.theme.sidebar.appIcon}; */
        width: 2.5em;
        height: 2.5em;
      }

      &.app-icon {
        svg {
          width: 3em;
          height: 3em;
          fill: ${(props) => props.theme.sidebar.appIcon};
        }
      }
    }
  }

  nav {
    ul {
      .menuitem {
        list-style-type: none;
        height: 4.25em;

        button {
          width: 100%;
          height: 100%;
          color: ${(props) => props.theme.color.gray700};
          background: transparent;
          outline: none;
          border: none;
          cursor: pointer;
          border-left: 0.25em solid transparent;
          border-right: 0.25em solid transparent;

          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 2.2em;
            height: 2.2em;
            fill: currentColor;
          }
        }
        &:not(.currently-selected-tab) {
          &:hover {
            background-color: var(--ant-color-bg-elevated);
            color: ${(props) => props.theme.color.gray500};
          }
        }

        &.currently-selected-tab button {
          background-color: var(--ant-color-bg-elevated);
          border-left: 0.25em solid var(--ant-color-primary);
          color: ${(props) => props.theme.color.gray200};

          &:hover {
            /* background-color: ${(props) =>
              props.theme.color.primary100} !important; */
            /* color: ${(props) => props.theme.color.gray200} !important; */
          }
        }
      }
    }
  }

  .apps-collapsed-btn {
    background-color: var(--ant-color-bg-elevated);
    color: ${(props) => props.theme.color.gray700};
    border: none;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.2em 1em;
    width: 100%;
    cursor: pointer;
    height: 4em;
    &:first-of-type {
      height: 4.5em;
    }
  }
  .part-bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div:first-of-type {
      // margin-bottom: 1em;

      a {
        display: flex;
      }
    }

    .apps-icon {
      cursor: pointer;
    }
  }
  .divider-container {
    margin-top: 1em;
    width: 100%;
    padding: 0.1em 0.25em;
  }
  .divider-container .ant-divider-horizontal {
    margin: 0;
    height: 1px;
    border-radius: 1px;
    background: ${(props) => props.theme.color.gray900};
  }
`;

// const StyledBell = styled(Bell)`
//   fill: ${(props) => props.theme.color.gray300};
// `;

// const StyledAllAppsIcon = styled(AllAppsIcon)`
//   height: 1.6em;
//   width: 1.6em;
//   fill: ${(props) => props.theme.color.gray700};
// `;

interface Tab {
  id: string;
  title: string;
  icon: JSX.Element;
  onClick(): void;
}
interface SidebarProps {
  logo: JSX.Element;
  appIcon?: JSX.Element;
  appName?: string;
  tabs: Tab[];
}

function Sidebar(props: {
  sidebarProps: SidebarProps;
  className?: string;
}): JSX.Element {
  // Get User
  const user = useReactiveVar(userVar);

  const router = useRouter();
  const currentTab: string = router.query.tab
    ? `${router.query.tab}`
    : props.sidebarProps.tabs[0].id;

  // profile drawer
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = React.useState(false);
  const showProfileDrawer = () => {
    setIsProfileDrawerOpen(true);
  };
  const profileDrawerClose = () => {
    setIsProfileDrawerOpen(false);
  };

  // notifications drawer
  const [isNotificationsDrawerOpen, setIsNotificationsDrawerOpen] =
    React.useState(false);
  // const showNotificationsDrawer = () => {
  //   setIsNotificationsDrawerOpen(true);
  // };
  const notificationsDrawerClose = () => {
    setIsNotificationsDrawerOpen(false);
  };

  const [currentlySelectedTab, setCurrentlySelectedTab] = React.useState('');

  if (currentlySelectedTab === '' || currentlySelectedTab !== currentTab) {
    setCurrentlySelectedTab(currentTab);
  }

  return (
    <StyledDiv>
      <div className="part-top">
        <div className="icon logo">{props.sidebarProps.logo}</div>
        {props.sidebarProps.appIcon && (
          <div className="icon app-icon">
            <Tooltip title={props.sidebarProps.appName} placement="right">
              {props.sidebarProps.appIcon}
            </Tooltip>
          </div>
        )}
        <nav>
          <ul>
            {props.sidebarProps.tabs.map((tab: Tab) => (
              <li
                className={`menuitem ${
                  currentTab === tab.id ? 'currently-selected-tab' : ''
                }`}
                key={tab.title}
              >
                <Tooltip title={tab.title} placement="right">
                  <button
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick={currentTab === tab.id ? () => {} : tab.onClick}
                  >
                    {tab.icon}
                  </button>
                </Tooltip>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="part-bottom">
        {/* all apps option disabled for now */}
        {/* {router.pathname !== '/' && (
          <Tooltip title="Apps" placement="right">
            <div>
              <Link href="/" passHref shallow>
                // eslint-disable-next-line jsx-a11y/anchor-is-valid

                <StyledAllAppsIcon className="apps-icon" />
              </Link>
            </div>
          </Tooltip>
        )} */}

        <div className={'divider-container'}>
          <Divider className={'divider'} />
        </div>

        {/* <button
          className="apps-collapsed-btn"
          onClick={() => {
            showNotificationsDrawer();
            console.log('preferences clicked');
          }}
          onKeyDown={() => showNotificationsDrawer()}
        >
          <StyledBell className="apps-icon" />
        </button> */}
        <button
          className="apps-collapsed-btn"
          onClick={() => showProfileDrawer()}
        >
          <div className={'avatar'}>
            <Avatar
              name={user?.name}
              size="2.2em"
              textSizeRatio={2.2}
              round={true}
              maxInitials={2}
            />
          </div>
        </button>
      </div>
      <NotificationDrawer
        isNotificationsDrawerOpen={isNotificationsDrawerOpen}
        notificationsDrawerClose={notificationsDrawerClose}
        closable={false}
      />
      <ProfileDrawer
        isProfileDrawerOpen={isProfileDrawerOpen}
        profileDrawerClose={profileDrawerClose}
      />
    </StyledDiv>
  );
}

export default Sidebar;
