import styled from '@emotion/styled';
import { Flex, Input } from 'antd';

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-size: 1.25rem;
`;

export const StyledPage = styled.section`
  height: calc(100vh - 62px);
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 0.1fr auto 0.1fr;
  position: relative;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    padding: 1.5em;
  }

  .background {
    position: absolute;
    width: 50%;
    height: auto;
    opacity: 1;
    top: 0;
    right: 0;
    pointer-events: none;
  }
`;

export const StyledAllAppsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  overflow: auto;
  height: 100%;
  padding: 1.5em;
  padding-top: 0;
`;

export const StyledAppsSection = styled.section`
  .heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.7em;
    margin-bottom: 1em;

    svg {
      width: 2.5em;
    }
    h1 {
      font-size: 0.9em;
      letter-spacing: 0.25em;
      text-transform: uppercase;
      margin: 0;
    }
  }
  .apps {
    display: flex;
    flex-direction: row;
    gap: 1.5em;
    flex-wrap: wrap;
  }
`;

export const StyledApp = styled(Flex)`
  cursor: pointer;
  height: 7em;
  aspect-ratio: 1;
  padding: 1em 0.5em;
  text-align: center;
  line-height: 1.2;
  border: 1px solid var(--ant-color-border);
  transition: 300ms ease;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0.5em;
  justify-items: center;
  justify-content: center;
  border-radius: 10px;

  &:hover {
    /* background: var(--ant-color-bg-elevated); */
  }

  svg {
    width: 2.75em;
    // height: 2.75em;
  }

  span {
    font-size: 0.9em;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
`;

export const StyledTitle = styled.h2`
  font-size: 1em;
  margin: 0;
`;

export const StyledSearchInput = styled(Input)`
  width: 40%;
  max-width: 40rem;
  height: 2.5em;
  color: ${(props) => props.theme.color.gray800};
  border: none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 0;

  &:hover,
  &:focus-within {
    border: none;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .ant-input {
    color: ${(props) => props.theme.color.gray800};
    border: none;
    height: 100%;
    font-size: 1.1em;
    padding: 0.5em 1em;
    padding-bottom: 0.4em;
    box-shadow: none;

    &::placeholder {
      color: ${(props) => props.theme.color.gray800};
    }
  }

  .ant-input-suffix {
    cursor: pointer;
  }
  .ant-input-group-addon {
    .ant-input-search-button {
      background: ${(props) => props.theme.home.inputBg};
      border: none;
      height: 100%;
      font-size: 1.1em;
      padding-right: 0.5em;
      box-shadow: none;
      .anticon-search {
        color: ${(props) => props.theme.color.gray800};
      }
    }
  }
`;

export const StyledFooter = styled.div`
  width: 100%;
  margin-top: auto;
  background: ${(props) => props.theme.color.primary200};

  .title {
    position: relative;
    background: ${(props) => props.theme.color.primary800};
    padding: 0.4em 2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;
    width: 60%;
    z-index: 0;

    span {
      font-size: 1.1em;
      line-height: 1;
    }

    .icon {
      display: flex;
      align-items: center;
      svg {
        height: 1.4em;
      }
    }

    .accent-design {
      position: absolute;
      z-index: -1;
      width: calc(100% + 3rem);
      height: 100%;
      top: 0;
      left: 0;
      transform: skew(-50deg);
      background: inherit;
      border-right: 2px solid ${(props) => props.theme.color.accent700};
      border-bottom: 1px solid ${(props) => props.theme.color.accent700};

      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        right: -8rem;
        width: 8rem;
        height: 1px;
        color: ${(props) => props.theme.color.accent700};
        background: currentColor;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: -0.45rem;
        right: -9rem;
        height: 1rem;
        width: 1rem;
        transform: skew(50deg);
        border-radius: 50%;
        border: 1px solid ${(props) => props.theme.color.accent700};
      }
    }
  }

  .third-parties {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;
    width: min(75%, 1200px);
    padding: 0.3em 2em;
  }
`;
