import React from 'react';

import styled from '@emotion/styled';
import { Modal as BaseModal } from 'antd';

import getAlphaHex from '@utils/getAlphaHex';

interface IModalProps {
  isOpen: boolean;
  closeModal(): void;
  title?: string;
  text?: string;
  children?: React.ReactNode;
  type?: 'success' | 'warning' | 'error' | 'info';
  okText?: string | null;
  cancelText?: string | null;
  onOk?(): void;
  onCancel?(): void;
  icon?: React.ReactNode;
  closable: boolean;
  width?: number;
  centered?: boolean;
  maskClosable?: boolean;
}

const StyledBaseModal = styled(BaseModal)`
  font-size: 1rem;
  max-width: min(650px, 50%);
  .ant-modal-content {
    border-radius: 0.5rem;
    background: transparent;
    overflow: hidden;
  }
  .ant-modal-close-x {
    color: ${(props) => props.theme.body.textColor};
    svg {
      width: 1.25em;
      height: 1.25em;
      margin-top: 1.25em;
    }
  }
`;

const ModalBody = styled.div`
  display: flex;
  background-color: var(--ant-color-bg-container);
  padding: 2.5rem;
  border-radius: 10px 10px 0px 0px;
`;

const ModalIconContainer = styled.div`
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 2rem;
    width: auto;
    fill: currentColor;
  }

  &.success {
    background-color: ${(props) => props.theme.color.successLighten};
    color: ${(props) => props.theme.color.success};
  }
  &.warning {
    background-color: ${(props) => props.theme.color.warningLighten};
    color: ${(props) => props.theme.color.warning};
  }
  &.error {
    background-color: ${(props) =>
      getAlphaHex(props.theme.color.danger500, 20)};
    color: ${(props) => props.theme.color.danger500};
  }
  &.info {
    background-color: ${(props) => props.theme.color.infoLighten};
    color: ${(props) => props.theme.color.info};
  }
`;

const ModalIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 60px;
  margin-right: 1rem;
`;

const ModalContent = styled.div`
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ModalTitle = styled.div`
  font-size: 1.1em;
  margin-bottom: 5px;
  font-weight: 600;
  color: ${(props) => props.theme.modal.textHeading};
`;

const ModalText = styled.div`
  font-size: 0.9em;
  color: ${(props) => props.theme.modal.textContent};
`;

const ModalFooter = styled.div`
  background-color: var(--ant-color-bg-elevated);
  padding: 1.5em;
  border-radius: 0px 0px 10px 10px;
`;
const ModalButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    padding: 0.6em 2em;
    padding-top: 0.65em;
    cursor: pointer;
    outline: none;
    text-transform: uppercase;
    font-size: 1.1em;
    line-height: 1;
    letter-spacing: 0.2em;
    background: transparent;
    color: ${(props) => props.theme.modal.textButton};
    border: 0.075em solid ${(props) => props.theme.modal.borderButton};
    border-radius: 0.2em;
  }

  button:hover {
    /* background: ${(props) => props.theme.modal.bgButtonHover}; */
    color: var(--ant-color-primary);
    border: 0.075em solid var(--ant-color-primary);
  }

  button:active,
  button:focus {
    background: transparent;
    /* color: ${(props) => props.theme.modal.textButtonActive}; */
  }

  button:first-of-type {
    margin-right: 1.5em;
  }
`;

function Modal(props: IModalProps): JSX.Element {
  const onOk = () => {
    props.closeModal();
    if (typeof props.onOk === 'function') {
      props.onOk();
    }
  };

  const onCancel = () => {
    props.closeModal();
    if (typeof props.onCancel === 'function') {
      props.onCancel();
    }
  };

  return (
    <React.Fragment>
      <StyledBaseModal
        title={null}
        footer={null}
        closable={props.closable}
        open={props.isOpen}
        // bodyStyle={{ padding: 0 }}
        style={{ margin: 'auto' }}
        width={props.width}
        onCancel={props.closeModal}
        centered={props.centered}
        maskClosable={props.maskClosable}
      >
        <ModalBody>
          {props.icon && (
            <ModalIcon>
              <ModalIconContainer className={props.type}>
                {props.icon}
              </ModalIconContainer>
            </ModalIcon>
          )}
          <ModalContent>
            {props.title && <ModalTitle>{props.title}</ModalTitle>}
            {props.text && <ModalText>{props.text}</ModalText>}
          </ModalContent>
          {props.children}
        </ModalBody>
        {props.okText && props.cancelText && (
          <ModalFooter>
            <ModalButtons>
              <button onClick={onCancel}>{props.cancelText}</button>
              <button onClick={onOk}>{props.okText}</button>
            </ModalButtons>
          </ModalFooter>
        )}
      </StyledBaseModal>
    </React.Fragment>
  );
}

Modal.defaultProps = {
  closable: false,
};

export default Modal;
