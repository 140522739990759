/* eslint-disable sonarjs/no-duplicate-string */
import { palette } from './../../../@types/palette';
export const ligthPallete: palette = {
  type: 'light',
  primary: {
    50: '#2A3A50',
    100: '#122037',
    200: '#0E2544',
    300: '#0E1E3D',
    400: '#0C1729',
    500: '#0A1E3A',
    600: '#071530',
    700: '#07152A',
    800: '#000D26',
    900: '#040F23',
  },
  accent: {
    300: '#7DC6FF',
    500: '#8AB4F8',
    700: '#3FE7E7',
  },
  hover: {
    300: '#0E1E3D',
  },
  highlight: {
    100: '#FFFF33',
  },
  gray: {
    50: '#FFFFFF',
    100: '#F2F2F2',
    200: '#E5E5E5',
    300: '#DDE0E8',
    400: '#D4D5D6',
    500: '#D1D1D1',
    600: '#C6C7C8',
    700: '#C0C4C8',
    800: '#B6B9BC',
    900: '#989898',
  },
  waiting: {
    300: '#EACC0E',
  },
  pending: {
    300: '#00CFB3',
  },
  active: {
    300: '#8DFD29',
  },
  success: {
    300: '#32F62B',
    500: '#26C400',
    700: '#20A600',
    900: '#00730C',
  },
  danger: {
    300: '#FF2D39',
    500: '#E02424',
    700: '#D90E0E',
    900: '#850002',
  },
  warning: {
    300: '#FF9A39',
    500: '#F5881F',
    700: '#E26F00',
    900: '#B53200',
  },
  info: {
    300: '#7DC6FF',
    500: '#42A5F2',
    700: '#0072CC',
    900: '#00488F',
  },
  appIcon: '#60E4FF',
};

export const darkPallete: palette = {
  type: 'dark',
  primary: {
    50: '#2A3A50',
    100: '#0E2544',
    200: '#0E1E3D',
    300: '#0A1E3A',
    400: '#122037',
    500: '#071530',
    600: '#07152A',
    700: '#0C1729',
    800: '#000D26',
    900: '#040F23',
  },
  accent: {
    300: '#7DC6FF',
    500: '#8AB4F8',
    700: '#3FE7E7',
  },
  hover: {
    300: '#0E1E3D',
  },
  highlight: {
    100: '#FFFF33',
    500: '#FFFF00',
  },
  gray: {
    50: '#FEFEFF',
    100: '#EDEEF0',
    200: '#E1E2E5',
    300: '#DDE0E8',
    400: '#D4D5D6',
    500: '#CECFD0',
    600: '#C6C7C8',
    700: '#C0C4C8',
    800: '#B6B9BC',
    900: '#909295',
  },
  waiting: {
    300: '#EACC0E',
  },
  pending: {
    300: '#00CFB3',
  },
  active: {
    300: '#8DFD29',
  },
  success: {
    300: '#32F62B',
    500: '#26C400',
    700: '#20A600',
    900: '#00730C',
  },
  alert: {
    300: '#FF9A39',
    500: '#F5881F',
    700: '#E26F00',
    900: '#B53200',
  },
  error: {
    300: '#FF2D39',
    500: '#E02424',
    700: '#D90E0E',
    900: '#850002',
  },
  danger: {
    300: '#FF2D39',
    500: '#E02424',
    700: '#D90E0E',
    900: '#850002',
  },
  warning: {
    300: '#FF9A39',
    500: '#F5881F',
    700: '#E26F00',
    900: '#B53200',
  },
  info: {
    300: '#7DC6FF',
    500: '#42A5F2',
    700: '#0072CC',
    900: '#00488F',
  },
  appIcon: '#60E4FF',
};

// export const antdThemeToken = {
//   token: {
//     colorPrimary: '#1ecbff',
//     colorInfo: '#1ecbff',
//     colorSuccess: '#32f62b',
//     colorWarning: '#ff9a39',
//     colorError: '#ff2d39',
//     colorSuccessTextHover: '#227a1e',
//     colorSuccessText: '#32f62b',
//     colorWarningText: '#ff9a39',
//     colorInfoText: '#7dc6ff',
//     colorInfoTextHover: '#0072cc',
//     colorLink: '#dcdcdc',
//     colorLinkHover: '#ededed',
//     colorWarningActive: '#e26f00',
//     colorWarningTextActive: '#e26f00',
//     colorErrorText: '#ff2d39',
//     colorErrorBorderHover: '#850002',
//     colorErrorActive: '#d90e0e',
//     colorErrorTextActive: '#d90e0e',
//     colorSuccessBorderHover: '#00730c',
//     colorSuccessActive: '#20a600',
//     colorSuccessTextActive: '#20a600',
//     colorSuccessHover: '#227a1e',
//     colorWarningBorderHover: '#9e5920',
//     colorWarningHover: '#e8a359',
//     colorPrimaryActive: '#1b8bad',
//     colorPrimaryHover: '#42c6e8',
//     colorPrimaryTextHover: '#42c6e8',
//     colorPrimaryText: '#1db0dc',
//     colorPrimaryTextActive: '#1b8bad',
//   },
//   components: {
//     Dropdown: {
//       colorError: 'rgb(255,45,57)',
//       colorPrimary: 'rgb(30,203,255)',
//     },
//     Button: {
//       colorPrimary: 'rgb(30,203,255)',
//       colorPrimaryActive: 'rgb(15,163,217)',
//       colorPrimaryBg: 'rgb(235,254,255)',
//       colorPrimaryBgHover: 'rgb(194,249,255)',
//       colorPrimaryBorder: 'rgb(153,241,255)',
//       colorPrimaryHover: 'rgb(71,218,255)',
//       colorPrimaryTextActive: 'rgb(15,163,217)',
//       colorPrimaryTextHover: 'rgb(71,218,255)',
//       colorError: 'rgb(224,36,36)',
//     },
//     Form: {
//       colorError: 'rgb(255,45,57)',
//       colorPrimary: 'rgb(30,203,255)',
//       colorWarning: 'rgb(255,154,57)',
//     },
//     Timeline: {
//       colorSuccess: 'rgb(50,246,43)',
//       colorError: 'rgb(255,45,57)',
//       colorPrimary: 'rgb(30,203,255)',
//     },
//     Notification: {
//       colorSuccess: 'rgb(50,246,43)',
//       colorError: 'rgb(255,45,57)',
//       colorInfo: 'rgb(66,165,242)',
//       colorPrimary: 'rgb(30,203,255)',
//       colorPrimaryBorder: 'rgb(23,75,91)',
//       colorPrimaryBorderHover: 'rgb(25,102,126)',
//       colorWarning: 'rgb(255,154,57)',
//     },
//     Typography: {
//       colorError: 'rgb(255,45,57)',
//       colorSuccess: 'rgb(50,246,43)',
//       colorWarning: 'rgb(255,154,57)',
//     },
//     Anchor: {
//       colorPrimary: 'rgb(30,203,255)',
//     },
//     Breadcrumb: {
//       colorPrimaryBorder: 'rgb(23,75,91)',
//     },
//     Pagination: {
//       colorPrimary: 'rgb(30,203,255)',
//     },
//     Steps: {
//       colorPrimary: 'rgb(30,203,255)',
//     },
//     Cascader: {
//       colorPrimary: 'rgb(30,203,255)',
//       colorHighlight: 'rgb(217,14,14)',
//     },
//     Checkbox: {
//       colorPrimary: 'rgb(30,203,255)',
//     },
//     DatePicker: {
//       colorError: 'rgb(255,45,57)',
//       colorPrimary: 'rgb(30,203,255)',
//       colorWarning: 'rgb(255,154,57)',
//     },
//     Input: {
//       colorError: 'rgb(255,45,57)',
//       colorWarning: 'rgb(255,154,57)',
//     },
//     InputNumber: {
//       colorWarning: 'rgb(255,154,57)',
//       colorError: 'rgb(255,45,57)',
//     },
//     Mentions: {
//       colorError: 'rgb(255,45,57)',
//       colorWarning: 'rgb(255,154,57)',
//     },
//     Radio: {
//       colorPrimary: 'rgb(30,203,255)',
//     },
//     Select: {
//       colorError: 'rgb(255,45,57)',
//       colorPrimary: 'rgb(30,203,255)',
//       colorWarning: 'rgb(255,154,57)',
//     },
//     Slider: {
//       colorPrimaryBorderHover: 'rgb(25,102,126)',
//     },
//     Switch: {
//       colorPrimary: 'rgb(30,203,255)',
//     },
//     Transfer: {
//       colorError: 'rgb(255,45,57)',
//       colorWarning: 'rgb(255,154,57)',
//     },
//     TreeSelect: {
//       colorPrimary: 'rgb(30,203,255)',
//       colorPrimaryHover: 'rgb(66,198,232)',
//     },
//     Upload: {
//       colorError: 'rgb(255,45,57)',
//       colorPrimary: 'rgb(30,203,255)',
//       colorPrimaryHover: 'rgb(66,198,232)',
//     },
//     Badge: {
//       colorError: 'rgb(255,45,57)',
//       colorErrorHover: 'rgb(232,80,83)',
//       colorSuccess: 'rgb(50,246,43)',
//       colorWarning: 'rgb(255,154,57)',
//       colorInfo: 'rgb(66,165,242)',
//     },
//     Calendar: {
//       colorPrimary: 'rgb(30,203,255)',
//       controlItemBgActive: 'rgb(18,36,44)',
//     },
//     Card: {
//       colorPrimary: 'rgb(30,203,255)',
//     },
//     List: {
//       colorPrimary: 'rgb(30,203,255)',
//     },
//     Table: {
//       colorPrimary: 'rgb(30,203,255)',
//       colorPrimaryBorder: 'rgb(23,75,91)',
//       controlItemBgActive: 'rgb(18,36,44)',
//     },
//     Tabs: {
//       colorPrimaryBorder: 'rgb(23,75,91)',
//     },
//     Tag: {
//       colorPrimary: 'rgb(30,203,255)',
//       colorPrimaryActive: 'rgb(27,139,173)',
//       colorPrimaryHover: 'rgb(66,198,232)',
//     },
//     Tree: {
//       colorPrimary: 'rgb(30,203,255)',
//       colorPrimaryBorder: 'rgb(23,75,91)',
//       colorPrimaryHover: 'rgb(66,198,232)',
//     },
//     Alert: {
//       colorError: 'rgb(255,45,57)',
//       colorErrorBg: 'rgb(44,19,22)',
//       colorInfo: 'rgb(66,165,242)',
//       colorInfoBorder: 'rgb(0,72,143)',
//       colorInfoBg: 'rgb(21,32,42)',
//       colorSuccess: 'rgb(50,246,43)',
//       colorSuccessBg: 'rgb(19,42,20)',
//       colorSuccessBorder: 'rgb(29,88,27)',
//       colorWarning: 'rgb(255,154,57)',
//       colorWarningBg: 'rgb(44,30,20)',
//       colorWarningBorder: 'rgb(91,60,31)',
//       colorErrorBorder: 'rgb(91,28,31)',
//     },
//     Drawer: {
//       colorPrimaryBorder: 'rgb(23,75,91)',
//     },
//     Message: {
//       colorError: 'rgb(255,45,57)',
//       colorInfo: 'rgb(66,165,242)',
//       colorSuccess: 'rgb(50,246,43)',
//       colorWarning: 'rgb(255,154,57)',
//     },
//     Modal: {
//       colorPrimaryBorder: 'rgb(23,75,91)',
//     },
//     Popconfirm: {
//       colorWarning: 'rgb(255,154,57)',
//     },
//     Progress: {
//       colorError: 'rgb(255,45,57)',
//       colorSuccess: 'rgb(50,246,43)',
//     },
//     Result: {
//       colorSuccess: 'rgb(50,246,43)',
//       colorError: 'rgb(255,45,57)',
//       colorInfo: 'rgb(66,165,242)',
//       colorWarning: 'rgb(255,154,57)',
//     },
//     Spin: {
//       colorPrimary: 'rgb(30,203,255)',
//     },
//   },
// };

export const antdThemeToken = {
  token: {
    colorSuccess: '#32f62b',
    colorWarning: '#ff9a39',
    colorError: '#ff2d39',
    colorSuccessTextHover: '#227a1e',
    colorSuccessText: '#32f62b',
    colorWarningText: '#ff9a39',
    colorInfoText: '#7dc6ff',
    colorInfoTextHover: '#0072cc',
    colorLink: '#dcdcdc',
    colorLinkHover: '#ededed',
    colorWarningActive: '#e26f00',
    colorWarningTextActive: '#e26f00',
    colorErrorText: '#ff2d39',
    colorErrorBorderHover: '#850002',
    colorErrorActive: '#d90e0e',
    colorErrorTextActive: '#d90e0e',
    colorSuccessBorderHover: '#00730c',
    colorSuccessActive: '#20a600',
    colorSuccessTextActive: '#20a600',
    colorSuccessHover: '#227a1e',
    colorWarningBorderHover: '#9e5920',
    colorWarningHover: '#e8a359',
    colorPrimary: '#237bf8',
    colorInfo: '#237bf8',
    colorPrimaryText: '#2b83fe',
    colorPrimaryBg: '#0f1929',
  },
  components: {
    Dropdown: {
      colorError: 'rgb(255,45,57)',
      colorPrimary: 'rgb(35,123,248)',
    },
    Button: {
      colorPrimary: 'rgb(35,123,248)',
      colorError: 'rgb(224,36,36)',
    },
    Form: {
      colorError: 'rgb(255,45,57)',
      colorPrimary: 'rgb(35,123,248)',
      colorWarning: 'rgb(255,154,57)',
    },
    Timeline: {
      colorSuccess: 'rgb(50,246,43)',
      colorError: 'rgb(255,45,57)',
      colorPrimary: 'rgb(35,123,248)',
    },
    Notification: {
      colorSuccess: 'rgb(50,246,43)',
      colorError: 'rgb(255,45,57)',
      colorInfo: 'rgb(66,165,242)',
      colorPrimary: 'rgb(35,123,248)',
      colorPrimaryBorder: 'rgb(25,51,88)',
      colorPrimaryBorderHover: 'rgb(27,66,123)',
      colorWarning: 'rgb(255,154,57)',
    },
    Typography: {
      colorError: 'rgb(255,45,57)',
      colorSuccess: 'rgb(50,246,43)',
      colorWarning: 'rgb(255,154,57)',
    },
    Anchor: {
      colorPrimary: 'rgb(35,123,248)',
    },
    Breadcrumb: {
      colorPrimaryBorder: 'rgb(25,51,88)',
    },
    Pagination: {
      colorPrimary: 'rgb(35,123,248)',
    },
    Steps: {
      colorPrimary: 'rgb(35,123,248)',
    },
    Cascader: {
      colorPrimary: 'rgb(35,123,248)',
      colorHighlight: 'rgb(217,14,14)',
      optionSelectedBg: 'rgb(15,25,41)',
    },
    Checkbox: {
      colorPrimary: 'rgb(35,123,248)',
    },
    DatePicker: {
      colorError: 'rgb(255,45,57)',
      colorPrimary: 'rgb(35,123,248)',
      colorWarning: 'rgb(255,154,57)',
      // fontSize: 18,
      // padding: 12,
      // fontSizeLG: 18,
      // activeBorderColor: '#141414',
      // activeHoverBorderColor: '#141414',
    },
    Input: {
      colorError: 'rgb(255,45,57)',
      colorWarning: 'rgb(255,154,57)',
      hoverBorderColor: 'rgb(35,123,248)',
    },
    InputNumber: {
      colorWarning: 'rgb(255,154,57)',
      colorError: 'rgb(255,45,57)',
      hoverBorderColor: 'rgb(35,123,248)',
    },
    Mentions: {
      colorError: 'rgb(255,45,57)',
      colorWarning: 'rgb(255,154,57)',
      hoverBorderColor: 'rgb(35,123,248)',
    },
    Radio: {
      colorPrimary: 'rgb(35,123,248)',
    },
    Select: {
      colorError: 'rgb(255,45,57)',
      colorPrimary: 'rgb(35,123,248)',
      colorWarning: 'rgb(255,154,57)',
      optionSelectedBg: 'rgb(15,25,41)',
      colorTextPlaceholder: '#9D9D9D',
      // fontSize: 18,
      // optionPadding: '8px 12px',
      // optionFontSize: 14,
    },
    Slider: {
      colorPrimaryBorderHover: 'rgb(27,66,123)',
      handleActiveColor: 'rgb(35,123,248)',
      trackHoverBg: 'rgb(30,87,168)',
    },
    Switch: {
      colorPrimary: 'rgb(35,123,248)',
      colorPrimaryHover: 'rgb(77,157,255)',
      colorPrimaryBorder: 'rgb(158,208,255)',
    },
    Transfer: {
      colorError: 'rgb(255,45,57)',
      colorWarning: 'rgb(255,154,57)',
    },
    TreeSelect: {
      colorPrimary: 'rgb(35,123,248)',
      colorPrimaryHover: 'rgb(71,143,232)',
    },
    Upload: {
      colorError: 'rgb(255,45,57)',
      colorPrimary: 'rgb(35,123,248)',
      colorPrimaryHover: 'rgb(71,143,232)',
    },
    Badge: {
      colorError: 'rgb(255,45,57)',
      colorErrorHover: 'rgb(232,80,83)',
      colorSuccess: 'rgb(50,246,43)',
      colorWarning: 'rgb(255,154,57)',
      colorInfo: 'rgb(66,165,242)',
    },
    Calendar: {
      colorPrimary: 'rgb(35,123,248)',
      controlItemBgActive: 'rgb(15,25,41)',
      itemActiveBg: 'rgb(15,25,41)',
    },
    Card: {
      colorPrimary: 'rgb(35,123,248)',
    },
    List: {
      colorPrimary: 'rgb(35,123,248)',
    },
    Table: {
      colorPrimary: 'rgb(35,123,248)',
      colorPrimaryBorder: 'rgb(25,51,88)',
      controlItemBgActive: 'rgb(15,25,41)',
      colorEvenRowBg: 'rgb(20 20 20)',
      colorOddRowBg: 'rgb(15 13 13)',
    },
    Tabs: {
      colorPrimaryBorder: 'rgb(25,51,88)',
      inkBarColor: 'rgb(43,131,254)',
      itemActiveColor: 'rgb(71,143,232)',
      itemHoverColor: 'rgb(71,143,232)',
      itemSelectedColor: 'rgb(35,123,248)',
    },
    Tag: {
      colorPrimary: 'rgb(35,123,248)',
      colorPrimaryActive: 'rgb(30,87,168)',
      colorPrimaryHover: 'rgb(71,143,232)',
    },
    Tree: {
      colorPrimaryBorder: 'rgb(25,51,88)',
      colorPrimaryHover: 'rgb(71,143,232)',
      colorPrimary: 'rgb(35,123,248)',
    },
    Alert: {
      colorError: 'rgb(255,45,57)',
      colorErrorBg: 'rgb(44,19,22)',
      colorInfo: 'rgb(66,165,242)',
      colorInfoBorder: 'rgb(9,55,100)',
      colorInfoBg: 'rgb(21,32,42)',
      colorSuccess: 'rgb(50,246,43)',
      colorSuccessBg: 'rgb(19,42,20)',
      colorSuccessBorder: 'rgb(14,65,13)',
      colorWarning: 'rgb(255,154,57)',
      colorWarningBg: 'rgb(44,30,20)',
      colorWarningBorder: 'rgb(71,44,19)',
      colorErrorBorder: 'rgb(83,19,22)',
    },
    Drawer: {
      colorPrimaryBorder: 'rgb(25,51,88)',
    },
    Message: {
      colorError: 'rgb(255,45,57)',
      colorInfo: 'rgb(66,165,242)',
      colorSuccess: 'rgb(50,246,43)',
      colorWarning: 'rgb(255,154,57)',
    },
    Modal: {
      colorPrimaryBorder: 'rgb(25,51,88)',
    },
    Popconfirm: {
      colorWarning: 'rgb(255,154,57)',
    },
    Progress: {
      colorError: 'rgb(255,45,57)',
      colorSuccess: 'rgb(50,246,43)',
      defaultColor: 'rgb(35,123,248)',
    },
    Result: {
      colorSuccess: 'rgb(50,246,43)',
      colorError: 'rgb(255,45,57)',
      colorInfo: 'rgb(66,165,242)',
      colorWarning: 'rgb(255,154,57)',
    },
    Spin: {
      colorPrimary: 'rgb(35,123,248)',
    },
    Menu: {
      itemSelectedColor: 'rgb(64,143,255)',
    },
    Carousel: {
      colorBgContainer: 'rgb(71,143,232)',
    },
    Text: {
      colorPrimary: 'rgb(255, 255, 255)',
    },
    Scroll: {
      colorBgBase: 'rgb(0 0 0)',
      colorBgContainer: 'rgb(31 31 31)',
      colorBgElevated: 'rgb(66 66 66)',
    },
  },
};
