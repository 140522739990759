import React from 'react';

import { Global, css, ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import { ConfigProvider, theme as antdTheme } from 'antd';
import Head from 'next/head';
// import StyledTopbar from '../modules/Topbar';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ThemeToggleContext from '@contexts/ThemeToggleContext';
import { ligthPallete, darkPallete, antdThemeToken } from '@theme/palette';
import Theme from '@theme/theme';
import useDarkMode from 'lib/use-dark-mode';
import { OnlineStatusProvider } from 'src/global/hooks/useOnlineStatus';

const StyledMain = styled.main`
  // margin-top: 5em;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

const MainLayoutContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

interface Props {
  title?: string;
  children: JSX.Element;
}

// Global page layout
function MainLayout(props: Props): JSX.Element {
  /**
   * Note:  'use-dark-mode' is a very old library.
   *        It had some dependency conflicts, so it's source code is added in `/lib` folder and modified there ti resolved the dependency conflict
   *        It is strongly advised to switch to a different librray/package or freshly implement persistant color theme
   */
  const darkMode = useDarkMode(true, {});
  // Selecting color palette depending on darkmode status

  const currentPalette = darkMode.value ? darkPallete : ligthPallete;

  // Create theme object
  const theme = Theme(currentPalette);

  const [isMounted, setIsMounted] = React.useState(false);
  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  const pageTitle = `KoiVision ${props.title ? '- ' + props.title : ''}`;

  return (
    <>
      {/* Head - this title will be displayed on the webpage */}
      <Head>
        <title>{pageTitle}</title>
      </Head>

      {/* Global styles can be added here - normalize css, font imports or styles for global components  */}
      <Global
        styles={css`
          @import url('https://fonts.googleapis.com/css2?family=Muli:wght@200;400;500;600;700&display=swap');
          :root {
            --ant-color-bg-base: ${antdThemeToken.components.Scroll
              .colorBgBase};
            --ant-color-bg-container: ${antdThemeToken.components.Scroll
              .colorBgContainer};
            --ant-color-bg-elevated: ${antdThemeToken.components.Scroll
              .colorBgElevated};
          }
          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }

          html {
            overflow: hidden;
            overflow-x: hidden;
            font-size: 16px;
          }

          ::-webkit-scrollbar {
            // width: 0; /* Remove scrollbar space */
            // background: transparent; /* Optional: just make scrollbar invisible */
          }

          ::-webkit-scrollbar {
            width: 8px;
          }

          ::-webkit-scrollbar-track {
            box-shadow: none;
            border-radius: 5px;
            background: var(--ant-color-bg-container);
          }

          ::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: var(--ant-color-bg-elevated);
          }

          // Remove up/down arrows from input type numbers
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          /* Firefox */
          input[type='number'] {
            -moz-appearance: textfield;
          }
          body {
            background-color: var(--ant-color-bg-base);
            color: ${theme.body.textColor};
            font-family: ${theme.fontFamily};
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: ${theme.body.textColor};
          }

          // ToastNotification || message component
          .success-toast .ant-message-notice-content {
            color: ${theme.toastNotification.text};
            background: ${theme.toastNotification.bgSuccess};
          }
          .error-toast .ant-message-notice-content {
            color: ${theme.toastNotification.text};
            background: ${theme.toastNotification.bgError};
          }
          .warning-toast .ant-message-notice-content {
            color: ${theme.toastNotification.text};
            background: ${theme.toastNotification.bgWarning};
          }
          .info-toast .ant-message-notice-content {
            color: ${theme.toastNotification.text};
            background: ${theme.toastNotification.bgInfo};
          }
          .ant-message-custom-content {
            display: flex;
            align-items: center;
            // justify-content: center;
            text-align: left;
          }
          .ant-message-custom-content span {
            width: 95%;
          }
          .ant-message-custom-content svg {
            width: 2.5em;
            height: 2.5em;
            margin-right: 0.5em;
          }

          // Sticky notification
          .success-sticky-notification {
            margin-right: 3em;
            // width: auto !important;
            max-width: 50em;
            color: ${theme.toastNotification.text} !important;
            background: ${theme.stickyNotification.bg} !important;
            border-left: 0.3em solid
              ${theme.stickyNotification.highlightSuccess};
          }
          .success-sticky-notification .ant-notification-notice-message {
            color: ${theme.stickyNotification.highlightSuccess};
          }
          // .success-sticky-notification
          //   .ant-notification-notice-description
          //   span {
          //   color: ${theme.stickyNotification.highlightSuccess};
          // }

          .error-sticky-notification {
            margin-right: 3em;
            // width: auto !important;
            max-width: 50em;
            color: ${theme.toastNotification.text} !important;
            background: ${theme.stickyNotification.bg} !important;
            border-left: 0.3em solid ${theme.stickyNotification.highlightError};
          }
          .error-sticky-notification .ant-notification-notice-message {
            color: ${theme.stickyNotification.highlightError};
          }
          // .error-sticky-notification .ant-notification-notice-description span {
          //   color: ${theme.stickyNotification.highlightError};
          // }

          .warning-sticky-notification {
            margin-right: 3em;
            // width: auto !important;
            max-width: 50em;
            color: ${theme.toastNotification.text} !important;
            background: ${theme.stickyNotification.bg} !important;
            border-left: 0.3em solid
              ${theme.stickyNotification.highlightWarning};
          }
          .warning-sticky-notification .ant-notification-notice-message {
            color: ${theme.stickyNotification.highlightWarning};
          }
          // .warning-sticky-notification
          //   .ant-notification-notice-description
          //   span {
          //   color: ${theme.stickyNotification.highlightWarning};
          // }

          .info-sticky-notification {
            margin-right: 3em;
            // width: auto !important;
            max-width: 50em;
            color: ${theme.toastNotification.text} !important;
            background: ${theme.stickyNotification.bg} !important;
            border-left: 0.3em solid ${theme.stickyNotification.highlightInfo};
          }
          .info-sticky-notification .ant-notification-notice-message {
            color: ${theme.stickyNotification.highlightInfo};
          }
          // .info-sticky-notification .ant-notification-notice-description span {
          //   color: ${theme.stickyNotification.highlightInfo};
          // }

          .ant-notification-close-x {
            color: ${theme.stickyNotification.text};
          }
          .ant-notification-notice-close-x {
            color: ${theme.stickyNotification.text};
          }
          .ant-notification-notice-icon svg {
            width: 2em !important;
            height: 2em !important;
            margin-right: 0.5em;
          }
          .ant-notification-notice-description {
            width: 90%;
            float: right;
          }
          .ant-notification-notice-message {
            width: 90%;
            float: right;
          }
        `}
      />

      {/* Emotions theme provider */}
      <ConfigProvider
        theme={{
          ...antdThemeToken,
          // algorithm: darkMode.value
          //   ? antdTheme.darkAlgorithm
          //   : antdTheme.defaultAlgorithm,
          algorithm: antdTheme.darkAlgorithm,
          cssVar: true,
          hashed: false,
        }}
      >
        <ThemeProvider theme={theme}>
          {/* DndProvider - required for drag and drop functionality used on the Split page */}
          <DndProvider backend={HTML5Backend}>
            {isMounted && (
              <ThemeToggleContext.Provider
                value={{
                  isDarkTheme: darkMode.value,
                  toggleTheme: darkMode.toggle,
                }}
              >
                {/* Online status provider - provides the network connectivity status of the user */}
                <OnlineStatusProvider>
                  {/* Global main layout */}
                  <MainLayoutContainer>
                    {/* <StyledTopbar /> */}
                    <StyledMain>{props.children}</StyledMain>
                  </MainLayoutContainer>
                </OnlineStatusProvider>
              </ThemeToggleContext.Provider>
            )}
          </DndProvider>
        </ThemeProvider>
      </ConfigProvider>
    </>
  );
}

export default MainLayout;
