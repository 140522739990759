import React from 'react';

// import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Tooltip as AntdTooltip, TooltipProps } from 'antd';

const TruncatedTextWrapper = styled.div`
  max-width: 300px; // Adjust this value as needed
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface CustomTooltipProps extends Omit<TooltipProps, 'title'> {
  title: React.ReactNode;
  maxWidth?: number;
}

const Tooltip: React.FC<CustomTooltipProps> = (props) => {
  // const theme = useTheme();
  const { title, maxWidth = 300, ...restProps } = props;
  const truncatedTitle = React.useMemo(() => {
    if (typeof title === 'string') {
      return (
        <TruncatedTextWrapper style={{ maxWidth: `${maxWidth}px` }}>
          {title}
        </TruncatedTextWrapper>
      );
    }
    return title;
  }, [title, maxWidth]);

  return (
    <AntdTooltip
      title={truncatedTitle}
      // color={props.color || theme.color.primary200}
      mouseEnterDelay={props.mouseEnterDelay || 0.3}
      trigger={props.trigger || 'hover'}
      placement={props.placement || 'top'}
      {...restProps}
    >
      {props.children}
    </AntdTooltip>
  );
};

export default Tooltip;
