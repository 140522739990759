import React from 'react';

import { useTranslation } from 'next-i18next';

import Modal from '@components/elements/Modal';
import logout from '@utils/logout';

interface Props {
  className?: string;
  children?: JSX.Element;
  isOpen: boolean;
  closeModal(): void;
  icon?: React.ReactNode;
}

const LogoutModal = (props: Props): JSX.Element => {
  const { t: common } = useTranslation('common');
  return (
    <div className={props.className}>
      <Modal
        type="error"
        isOpen={props.isOpen}
        closeModal={props.closeModal}
        closable={false}
        title={common('logoutModal.title')}
        okText={common('logoutModal.okText')}
        cancelText={common('logoutModal.cancelText')}
        icon={props.icon}
        text={common('logoutModal.text')}
        centered={true}
        width={584}
        onOk={() => {
          logout();
        }}
      ></Modal>
    </div>
  );
};

export default LogoutModal;
